<template>
  <div class="main">
    <div class="main-layout">
      <div class="content-card card-bg">
        <div class="header-block">
          <div class="content-card-header">
            <div class="card-header-position">
              <div class="content-card-title" style="font-weight: 500;">
                订单号：{{ data.orderNumber }}
              </div>
              <LightTag
                :text="orderStateFormatter(data.orderState)"
                :type="orderStateType(data.orderState)"
              ></LightTag>
              <LightTag
                :text="chargeStatusFormatter(data.chargeStatus)"
                :type="chargeStatusType(data.chargeStatus)"
              ></LightTag>
            </div>
            <div class="mini-button" @click="viewOrderTrace">
              订单状态跟踪
            </div>
          </div>
          <div class="split-line"></div>
        </div>
        <Steps>
          <Step
            v-for="(item, index) in stepList"
            :index="index + 1"
            :key="index"
            :data="item"
          ></Step>
        </Steps>
        <div class="info-card-container">
          <div class="info-card">
            <div class="info-card-content">
              <div class="info-card-title">订单信息</div>
              <div class="info-card-row">
                <div class="info-row-item">
                  <div class="info-row-label">站点名称</div>
                  <div class="info-row-value ellipsis" v-overflow-tooltip>
                    {{ data.plotName || '-' }}
                  </div>
                </div>
                <div class="info-row-item">
                  <div class="info-row-label">是否异常</div>
                  <div class="info-row-value ellipsis" v-overflow-tooltip>
                    {{ isAbnormalFormatter(data.isAbn) || '-' }}
                  </div>
                </div>
                <div class="info-row-item">
                  <div class="info-row-label">拔枪时间</div>
                  <div class="info-row-value ellipsis" v-overflow-tooltip>
                    {{ data.drawingTime || '-' }}
                  </div>
                </div>
                <div class="info-row-item">
                  <div class="info-row-label">终端编号</div>
                  <div class="info-row-value ellipsis" v-overflow-tooltip>
                    {{ data.connectorCode || '-'
                    }}{{ data.connectorName ? `(${data.connectorName})` : '' }}
                  </div>
                </div>
                <div class="info-row-item">
                  <div class="info-row-label">启动方式</div>
                  <div class="info-row-value ellipsis" v-overflow-tooltip>
                    {{ startModeFormatter(data.startMode) || '-' }}
                  </div>
                </div>
                <div class="info-row-item" v-if="!isOperator">
                  <div class="info-row-label">运营商名称</div>
                  <div class="info-row-value ellipsis" v-overflow-tooltip>
                    {{ data.operationName || '-' }}
                  </div>
                </div>
                <div class="info-row-item">
                  <div class="info-row-label">电桩编号</div>
                  <div class="info-row-value ellipsis" v-overflow-tooltip>
                    {{ data.pileCode || '-'
                    }}{{ data.pileName ? ` (${data.pileName})` : '' }}
                  </div>
                </div>
                <div class="info-row-item">
                  <div class="info-row-label">停充原因</div>
                  <div class="info-row-value ellipsis" v-overflow-tooltip>
                    {{ data.stopReason || '-' }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="info-card" style="max-width:450px">
            <div class="info-card-content">
              <div class="info-card-title">车主信息</div>
              <div class="info-card-row">
                <div class="info-row-item info-row-item-right">
                  <div class="info-row-label">车主手机</div>
                  <div class="info-row-value ellipsis" v-overflow-tooltip>
                    {{ data.userTelephoneNo || '-' }}
                  </div>
                </div>
                <div class="info-row-item info-row-item-right">
                  <div class="info-row-label">车牌号</div>
                  <div class="info-row-value ellipsis" v-overflow-tooltip>
                    {{ carFilter(data.userCarNo) || '未知车辆' }}
                  </div>
                </div>
                <div class="info-row-item" style="flex-grow: 1;">
                  <div class="info-row-label">品牌型号</div>
                  <div class="info-row-value ellipsis" v-overflow-tooltip>
                    {{ data.userCarInfo || '-' }}
                  </div>
                </div>
                <div class="info-row-item" style="flex-grow: 1;">
                  <div class="info-row-label">VIN码</div>
                  <div class="info-row-value ellipsis" v-overflow-tooltip>
                    {{ data.userCarVin || '-' }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="content-card">
        <div class="content-card-header">
          <div class="content-card-title">
            费用明细
          </div>
          <div>
            <div class="mini-button" @click="handleRefund" v-if="refundVisible">
              <svg-icon icon-class="refund" />
              退款
            </div>
            <div class="refund-tips-text" v-if="isPartlyRefund">
              已部分退款
              <el-tooltip
                class="item"
                effect="dark"
                content="每个订单最多退款一次"
                placement="top-end"
              >
                <svg-icon
                  icon-class="question-circle"
                  style="height: 12px;width: 12px;margin-left: 2px"
                ></svg-icon>
              </el-tooltip>
            </div>
          </div>
        </div>
        <div class="inner-info-box-container">
          <div class="inner-info-box">
            <div class="detail-container">
              <div class="info-panel">
                <div>实付金额（元）</div>
                <div class="info-number">
                  {{ data.amountStr || '0' }}
                </div>
                <div class="info-suffix">
                  实付电费：{{ data.chargeAmountStr || '0.00' }}元 +
                  实结服务费：{{ data.serviceAmountStr || '0.00' }}元
                </div>
              </div>

              <div class="equipment-icon">=</div>
              <div class="minus-box">
                <div class="info-panel">
                  <div>订单金额（元）</div>
                  <div class="info-number">
                    {{ data.realAmountStr || '0.00' }}
                  </div>
                  <div class="info-suffix">
                    充电电费：{{
                      data.realChargeAmountStr
                        ? data.realChargeAmountStr
                        : '0.00'
                    }}元 + 充电服务费：{{
                      data.realServiceAmountStr
                        ? data.realServiceAmountStr
                        : '0.00'
                    }}元
                  </div>
                </div>
              </div>
              <div class="equipment-icon">-</div>
              <div class="minus-box">
                <div class="info-panel">
                  <div>会员折扣金额（元）</div>
                  <div class="info-number">
                    {{ data.discountAmountStr || '0.00' }}
                  </div>
                  <div class="info-suffix">
                    服务费：{{ data.discountRatioStr || '-' }}
                  </div>
                </div>
              </div>
              <div class="equipment-icon">-</div>
              <div class="minus-box">
                <div class="info-panel">
                  <div>优惠券抵扣金额（元）</div>
                  <div class="info-number">
                    {{ data.couponAmountStr || '0.00' }}
                  </div>
                  <div class="info-suffix">
                    {{ data.couponName || '-' }}
                  </div>
                </div>
              </div>
            </div>
            <div style="width: 100%">
              <el-table
                :data="data.orderExpensesDtos"
                border
                :header-row-style="{
                  fontWeight: 500,
                  fontSize: '14px',
                }"
                :row-style="{
                  fontSize: '14px',
                }"
                :cell-style="{
                  borderColor: '#e5e6eb',
                }"
              >
                <el-table-column prop="periods" label="时段" min-width="180">
                  <template slot-scope="{ row }">
                    <span v-for="(period, index) in row.periods" :key="index">
                      {{ period.beginTime }} - {{ period.endTime }}
                      <span class="span-badge" v-if="period.crossDay">+1</span>
                      <span v-if="index < row.periods.length - 1">、</span>
                    </span>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="classify"
                  label="时段标识"
                  min-width="100"
                >
                  <template slot-scope="{ row }">
                    <PlainTag
                      :color="classifyColor(row.classify)"
                      :bgColor="classifyBgColor(row.classify)"
                      :text="classifyFormatter(row.classify)"
                    ></PlainTag>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="powerStr"
                  label="电量（度）"
                  min-width="100"
                >
                </el-table-column>
                <el-table-column
                  prop="chargePriceStr"
                  label="电费单价（元）"
                  min-width="140"
                >
                </el-table-column>
                <el-table-column
                  prop="servicePriceStr"
                  label="服务费单价（元）"
                  min-width="140"
                >
                </el-table-column>
                <el-table-column
                  prop="amountStr"
                  label="小计（元）"
                  min-width="100"
                >
                </el-table-column>
              </el-table>
            </div>
          </div>

          <div class="inner-info-box-right" style=" width: 252px">
            <div class="card-header-position">
              <div class="content-card-title">
                支付方式
              </div>
              <LightTag
                v-if="data.payType"
                :text="paywayFormatter(data.payType)"
              ></LightTag>
            </div>
            <div class="timeline-box-container">
              <div class="timeline-box-container-inner">
                <el-timeline v-if="payFlowList.length > 0">
                  <el-timeline-item
                    v-for="(activity, index) in payFlowList"
                    :key="index"
                    :color="activity.color"
                    :size="activity.size"
                  >
                    <div class="timeline-box">
                      <div
                        class="timeline-box-label"
                        :style="{ color: index === 0 ? '#1D2129' : '' }"
                      >
                        <div
                          class="timeline-label-position"
                          style="font-size:14px;"
                          :class="{
                            is_error:
                              activity.amount > 0 &&
                              activity.itemType == 0 &&
                              index === 0,
                          }"
                        >
                          <span style="font-weight:500;text-wrap: nowrap">{{
                            payFlowTypeFormatter(activity.itemType)
                          }}</span>
                          <!-- <div
                            class="card-tag__mini card-tag__mini-info"
                            v-if="
                              activity.payType &&
                                ['已支付', '补支付'].includes(activity.label)
                            "
                          >
                            {{ paywayFormatter(activity.payType) }}
                          </div> -->
                          <LightTag
                            v-if="
                              activity.itemTag &&
                                [1].includes(activity.itemType)
                            "
                            :text="activity.itemTag"
                            color="#86909C"
                            type="cancel"
                            size="mini"
                          ></LightTag>
                          <LightTag
                            v-if="
                              activity.itemTag &&
                                [11].includes(activity.itemType)
                            "
                            :text="activity.itemTag"
                            color="#86909C"
                            type="cancel"
                            size="mini"
                          ></LightTag>
                          <el-popconfirm
                            v-if="[4].includes(activity.itemType)"
                            title="确认操作"
                            @confirm="retryRefund(activity.refundId)"
                          >
                            <div slot="reference" class="text-btn-error">
                              重试
                              <i class="el-icon-refresh"></i>
                            </div>
                          </el-popconfirm>
                        </div>

                        <div
                          class="timeline-label-price"
                          :class="{
                            is_error:
                              activity.amount > 0 &&
                              activity.itemType == 0 &&
                              index === 0,
                          }"
                        >
                          {{ activity.amount }}元
                        </div>
                      </div>
                      <div class="timeline-box-layer">
                        <div
                          class="timeline-label-suffix"
                          v-if="
                            [1, 3].includes(activity.itemType) && activity.desc
                          "
                        >
                          {{ activity.desc }}
                        </div>
                        <div
                          class="timeline-label-suffix"
                          v-if="activity.itemType === 2"
                        >
                          原因：{{ activity.desc }}
                        </div>
                        <div class="timeline-box-time">
                          {{ activity.time }}
                        </div>
                      </div>
                    </div>
                  </el-timeline-item>
                </el-timeline>
                <div v-else>暂无数据</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="content-card">
        <div class="content-card-header">
          <div class="content-card-title">
            充电信息
            <PlainTag>
              <img class="mini-tag-icon" src="@/assets/order/vector.svg" />{{
                isDc ? '直流' : '交流'
              }}
            </PlainTag>
          </div>
          <div></div>
        </div>
        <div class="inner-info-box">
          <div class="inner-info-row">
            <div class="inner-info-row-item">
              <img class="card-icon" src="@/assets/order/充电时长.svg" />
              <div class="inner-info-row-layer">
                <div class="inner-info-row-label">充电时长</div>
                <div class="inner-info-row-time">
                  {{ data.realHourStr || '-' }}
                </div>
              </div>
            </div>
            <div class="inner-info-row-item">
              <img class="card-icon" src="@/assets/order/总耗电量.svg" />
              <div class="inner-info-row-layer">
                <div class="inner-info-row-label">充电量</div>
                <div class="inner-info-row-time">
                  {{ data.consumePowerStr || '-' }}度
                </div>
              </div>
            </div>
            <div class="inner-info-row-item" v-if="isDc">
              <img class="card-icon" src="@/assets/order/SOC.svg" />
              <div class="inner-info-row-layer">
                <div class="inner-info-row-label">起止SOC</div>
                <div class="inner-info-row-time">
                  {{ socFormatter(data) || '-' }}
                </div>
              </div>
            </div>
            <div class="inner-info-row-item" style="flex-shrink: 0;">
              <img class="card-icon" src="@/assets/order/充电方式.svg" />
              <div class="inner-info-row-layer">
                <div class="inner-info-row-label">充电方式</div>
                <div class="inner-info-row-time">
                  {{ data.deliveryTime ? '智能充电' : '普通充电' }}
                  <span class="inner-info-row-suffix">
                    <div v-if="isDc && data.deliveryTime">
                      目标充电量:
                      {{
                        data.targetPowerStr > 0 ? data.targetPowerStr : '-'
                      }}度
                    </div>
                    <div v-if="data.deliveryTime">
                      计划提车离场时间: {{ data.deliveryTime || '-' }}
                    </div>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="chart-box-layer">
          <div class="chart-box">
            <div class="chart-box-title">充电量{{ isDc ? '/SOC' : '' }}</div>
            <el-empty
              v-if="orderRealTimeNull"
              style="height: 334px;width: 100%;"
              image="https://cdn.lidian-icharging.com/_478152af17eb458ea7fd385c7ea5e27c.svg"
              :image-size="48"
            ></el-empty>
            <v-chart
              v-else
              ref="realTimeChartRef"
              style="height: 334px;"
              :option="orderRealTimeOption"
              autoresize
              group="group1"
            />
          </div>
          <div class="chart-box">
            <div class="chart-box-title">功率</div>
            <el-empty
              v-if="orderRealTimeNull"
              style="height: 334px;width: 100%;"
              image="https://cdn.lidian-icharging.com/_478152af17eb458ea7fd385c7ea5e27c.svg"
              :image-size="48"
            ></el-empty>
            <v-chart
              v-else
              ref="powerRealTimeChartRef"
              style="height: 334px;"
              :option="powerRealTimeOption"
              autoresize
              group="group1"
            />
          </div>
          <div class="chart-box">
            <div class="chart-box-title">电流</div>
            <el-empty
              v-if="orderRealTimeNull"
              style="height: 334px;width: 100%;"
              image="https://cdn.lidian-icharging.com/_478152af17eb458ea7fd385c7ea5e27c.svg"
              :image-size="48"
            ></el-empty>
            <v-chart
              v-else
              ref="electricityRealTimeChartRef"
              style="height: 334px;"
              :option="electricityRealTimeOption"
              autoresize
              group="group1"
            />
          </div>
          <div class="chart-box">
            <div class="chart-box-title">电压</div>
            <el-empty
              v-if="orderRealTimeNull"
              style="height: 334px;width: 100%;"
              image="https://cdn.lidian-icharging.com/_478152af17eb458ea7fd385c7ea5e27c.svg"
              :image-size="48"
            ></el-empty>
            <v-chart
              v-else
              ref="voltageRealTimeChartRef"
              style="height: 334px;"
              :option="voltageRealTimeOption"
              autoresize
              group="group1"
            />
          </div>
        </div>
      </div>
    </div>
    <el-drawer
      title="订单状态跟踪"
      direction="rtl"
      :visible.sync="orderTraceVisible"
      size="400px"
      style="border-radius: 8px 0px 0px 8px;"
    >
      <div style="padding: 0 24px;flex:1;width: 100%;">
        <el-timeline>
          <el-timeline-item
            v-for="(activity, index) in logList"
            :key="index"
            :size="index === 0 ? 'large' : ''"
            :color="index === 0 ? 'rgba(5, 124, 152, 0.08)' : 'transparent'"
          >
            <div class="timeline-box">
              <div class="timeline-box-label">
                <div class="timeline-label-position">
                  {{ activity.briefInfo }}
                </div>
                <div class="timeline-label-time">
                  {{ activity.createTime }}
                </div>
              </div>
              <div
                class="timeline-box-time"
                :style="{ color: activity.mainProcess == 0 ? '#F87700' : '' }"
              >
                <span style="white-space: pre-line;">{{
                  activity.logContent
                }}</span>
              </div>
            </div>
          </el-timeline-item>
        </el-timeline>
      </div>
      <span class="drawer-footer">
        <div class="drawer-footer-inner">
          <el-button type="" @click="orderTraceVisible = false"
            >关 闭</el-button
          >
        </div>
      </span>
    </el-drawer>
    <refundDialog
      ref="refundDialogRef"
      :data="data"
      @submit="handleRefundSubmit"
    ></refundDialog>
  </div>
</template>

<script setup>
import {
  getOrderDetail,
  getOrderTrace,
  orderRefund,
  getOrderRealTime,
  getOrderPayFlow,
  orderRefundReset,
} from '@/api/order';
import { ref, computed, set } from 'vue';
import Step from '@/components/Step/index.vue';
import Steps from '@/components/Steps/index.vue';
import { useRoute } from '@/hooks/useUtils';
import {
  CLASSIFY,
  ORDERSTATE_STATUS,
  PAY_WAY,
  CHARGE_STATUS,
  APPEND_TYPE,
  START_MODE,
  IS_ABNORMAL,
  PAY_FLOW_TYPE,
  findLabelByValue,
} from '@/utils/const';
import { carFilter } from '@/utils/formatter';
import PlainTag from '@/components/PlainTag/index.vue';
import LightTag from '@/components/LightTag/index.vue';
import refundDialog from './refundDialog.vue';
import { Message } from 'element-ui';
import { useChartOption } from './hooks/useChartOption';
import VChart from 'vue-echarts';
import { getName } from '@/utils/auth';

const {
  getChartSOCOption,
  getPowerLineOption,
  getElectricityLineOption,
  getVoltageLineOption,
} = useChartOption();

let data = ref({});
const route = useRoute();
const { id: orderNumber } = route.params;
// 是否直流
let isDc = ref(false);
// 获取运营商权限(是否运营商)
let isOperator = computed(() => {
  try {
    let name = JSON.parse(getName());
    return name.userInfo.isOperator === 1;
  } catch (error) {}
});
function getOrderData(orderNumber) {
  let param = {
    orderNumber,
  };
  getOrderDetail(param).then((res) => {
    if (res.message.status == 200) {
      data.value = res.data;
      isDc.value = data.value.pileType === 1;
      getOrderRealTimeData();
      setStepList(data.value.logList || []);
      try {
        data.value.orderExpensesDtos.forEach((item) => {
          item.periods = JSON.parse(item.periods);
        });
      } catch (error) {
        data.value.orderExpensesDtos = [];
      }
    }
  });
}
getOrderData(orderNumber);

// 字典
function classifyFormatter(val) {
  return findLabelByValue(CLASSIFY, val);
}
function classifyColor(val) {
  return findLabelByValue(CLASSIFY, val, 'color');
}
function classifyBgColor(val) {
  return findLabelByValue(CLASSIFY, val, 'bgColor');
}
function orderStateFormatter(val) {
  return findLabelByValue(ORDERSTATE_STATUS, val);
}
function orderStateType(val) {
  return findLabelByValue(ORDERSTATE_STATUS, val, 'type');
}
function chargeStatusFormatter(val) {
  return findLabelByValue(CHARGE_STATUS, val);
}
function chargeStatusType(val) {
  return findLabelByValue(CHARGE_STATUS, val, 'type');
}
function paywayFormatter(val) {
  return findLabelByValue(PAY_WAY, val);
}
function appendTypeFormatter(val) {
  return findLabelByValue(APPEND_TYPE, val);
}
function isAbnormalFormatter(val) {
  return findLabelByValue(IS_ABNORMAL, val);
}
function startModeFormatter(val) {
  return findLabelByValue(START_MODE, val);
}

function payFlowTypeFormatter(val) {
  return findLabelByValue(PAY_FLOW_TYPE, val);
}

//  起止soc格式化
function socFormatter(data) {
  return data.startSoc ? `${data.startSoc}% ~ ${data.stopSoc || '-'}%` : '-';
}

let orderTraceVisible = ref(false);
let logList = ref([]);
function getOrderTraceInfo() {
  getOrderTrace({
    orderNumber,
  }).then((res) => {
    if (res.message.status == 200) {
      logList.value = res.data || [];
      logList.value.reverse();
    }
  });
}
function viewOrderTrace() {
  orderTraceVisible.value = true;
  getOrderTraceInfo();
}

// 退款
let refundDialogRef = ref(null);
let refundVisible = computed(() => {
  return (
    +data.value.orderState == 3 &&
    +data.value.amount > 0 &&
    +data.value.refundedAmount === 0
  );
});
let isPartlyRefund = computed(() => {
  if (data.value.refundedAmount > 0) {
    return data.value.refundedAmount < data.value.refundAmount;
  }
  return false;
});

function handleRefund() {
  refundDialogRef.value.open();
}
function handleRefundSubmit(params) {
  orderRefund(params)
    .then((res) => {
      if (res.message.code == 0) {
        Message({
          message: '退款成功',
          type: 'success',
        });
        refundDialogRef.value.close();

        getOrderData(orderNumber);
        getOrderPayFlowInfo();
      } else {
        Message({
          message: res.message.message,
          type: 'error',
        });
      }
    })
    .catch((e) => {
      console.log('🚀 ~ file: orderDetail.vue:278 ~ e:', e);
    });
}

// 订单支付流水
let payFlowList = ref([]);
function getOrderPayFlowInfo() {
  getOrderPayFlow({
    orderNumber,
  }).then((res) => {
    if (res.message.status == 200) {
      let { data } = res;
      payFlowList.value = data;
      if (payFlowList.value.length > 0) {
        payFlowList.value[0].color = 'rgba(5, 124, 152, 0.08)';
        payFlowList.value[0].size = 'large';
        if (payFlowList.value[0].itemType == 5) {
          setTimeout(() => {
            getOrderPayFlowInfo();
          }, 3000);
        }
      }
    }
  });
}
getOrderPayFlowInfo();

// 获取图表数据
let orderRealTimeData = ref([]);
let orderRealTimeOption = ref({});
let powerRealTimeOption = ref({});
let electricityRealTimeOption = ref({});
let voltageRealTimeOption = ref({});
let orderRealTimeNull = ref(false);

let realTimeChartRef = ref(null);
let powerRealTimeChartRef = ref(null);
let electricityRealTimeChartRef = ref(null);
let voltageRealTimeChartRef = ref(null);

function getOrderRealTimeData() {
  getOrderRealTime({
    orderNumber,
  }).then((res) => {
    orderRealTimeData.value = res.data || [];
    orderRealTimeNull.value = orderRealTimeData.value.length == 0;

    orderRealTimeOption.value = getChartSOCOption(orderRealTimeData.value, {
      isDc: isDc.value,
    });
    powerRealTimeOption.value = getPowerLineOption(orderRealTimeData.value);
    electricityRealTimeOption.value = getElectricityLineOption(
      orderRealTimeData.value
    );
    voltageRealTimeOption.value = getVoltageLineOption(orderRealTimeData.value);
  });
}

// 设置时间轴
// state 0未开始 1进行中 2已完成
let stepList = ref([
  {
    briefInfo: '创建订单',
    state: 0,
  },
  {
    briefInfo: '开始充电',
    state: 0,
  },
  {
    briefInfo: '充电完成',
    state: 0,
  },
  {
    briefInfo: '结算完成',
    state: 0,
  },
  {
    briefInfo: '支付完成',
    state: 0,
  },
]);
function setStepList(data) {
  // 进行中标记
  let flag = 0;
  stepList.value.forEach((item) => {
    data.forEach((dataItem, index) => {
      if (item.briefInfo == dataItem.briefInfo) {
        item.state = 1;
        set(item, 'createTime', dataItem.createTime);
      } else {
        flag++;
        if (flag == index + 1) {
          item.state = 2;
          return;
        }
      }
    });
  });
}

// 退款重试
function retryRefund(refundId) {
  orderRefundReset({ id: 1 })
    .then((res) => {
      if (res.message.code == 200) {
        Message.success('操作成功');
        getOrderData(orderNumber);
      } else {
        Message.warning(res.message.message);
      }
    })
    .catch((e) => {
      console.log('🚀 ~ file: detail.vue:792 ~ e:', e);
    });
}
</script>

<style lang="scss" scoped>
.main-layout {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--Space-Padding-paddingSM, 12px);
}
.content-card {
  display: flex;
  padding: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  border-radius: 8px;
  border: 3px solid rgba(255, 255, 255, 0.85);
  background: var(--PC-, #fff);
  &.card-bg {
    background: linear-gradient(91deg, #ebf5f7 0.07%, #f5f2f0 100%);
  }
  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
  }
  &-title {
    color: var(--PC-, #1d2129);
    font-size: 16px;
    font-weight: 600;
  }
  .header-block {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--Space-Padding-paddingSM, 12px);
    align-self: stretch;
  }
  .card-header-position {
    display: flex;
    align-items: center;
    gap: 8px;
  }
}
.split-line {
  width: 100%;
  height: 1px;
  border-bottom: dashed rgba(0, 0, 0, 0.08) 1px;
}
.info-card-container {
  display: flex;
  align-items: flex-start;
  gap: var(--Space-Padding-paddingSM, 12px);
  align-items: stretch;
  border-radius: 8px;
  .info-row-value {
    flex: 1;
    flex-shrink: 0;
    font-size: 14px;
  }
}
.info-card {
  display: flex;
  padding: 16px;
  padding-right: 0;
  align-items: flex-start;
  gap: 24px;
  flex: 1 0 0;
  border-radius: 8px;
  border: 2px solid #fff;
  background: rgba(255, 255, 255, 0.65);
}
.info-card-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: var(--Space-Padding-paddingSM, 12px);
  flex: 1 0 0;
  .info-card-title {
    color: var(--PC-, #1d2129);
    font-family: 'PingFang SC';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .info-card-row {
    display: flex;
    align-items: flex-start;
    align-content: flex-start;
    gap: 8px;
    align-self: stretch;
    flex-wrap: wrap;
    .info-row-item {
      display: flex;
      min-width: 265px;
      align-items: center;
      gap: 8px;
      flex: 1 0 0;
      flex-grow: 0;
      &.info-row-item-right {
        min-width: 210px;
      }
      .info-row-label {
        color: var(--PC-, #86909c);
        font-family: 'PingFang SC';
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        // min-width: 56px;
      }
      .info-row-content {
        color: var(--PC-, #1d2129);
        font-family: 'PingFang SC';
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }
}
.mini-button {
  display: flex;
  padding: 4px 8px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 4px;
  background: var(--PC-, var(--current-color));
  color: #fff;
  font-family: 'PingFang SC';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  user-select: none;
  cursor: pointer;
  svg {
    width: 16px;
    height: 16px;
  }
  &:hover {
    opacity: 0.7;
  }
  &:active {
    opacity: 1;
  }
}
.inner-info-box-container {
  display: flex;
  align-items: flex-start;
  gap: var(--Space-Padding-paddingSM, 12px);
  align-self: stretch;
  align-items: stretch;
}
.inner-info-box-right {
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  border-radius: 8px;
  border: 1px solid var(--PC--, #e5e6eb);
}
.timeline-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  .timeline-box-time {
    color: #86909c;
    font-family: 'PingFang SC';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .timeline-box-layer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    align-self: stretch;
    .timeline-label-suffix {
      color: var(--PC-, #86909c);
      font-family: 'PingFang SC';
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
}
.timeline-box-label {
  color: var(--PC-, #4e5969);
  display: flex;
  height: 17px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  &.is_error {
    color: var(--PC-, #df3737);
  }
  .timeline-label-price {
    text-align: right;
    font-family: 'PingFang SC';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-right: 8px;
    &.is_error {
      color: var(--PC-, #df3737);
    }
  }
  .timeline-label-time {
    color: #86909c;
    text-align: right;
    font-family: 'PingFang SC';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
.timeline-label-position {
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  &.is_error {
    color: var(--PC-, #df3737);
  }
}
::v-deep .el-timeline-item__tail {
  position: absolute;
  left: 7px;
  top: 30%;
  height: 55%;
  border-left: 1px solid #e4e7ed;
}
::v-deep .el-timeline-item__node::before {
  content: '';
  width: var(--BorderRadius-borderRadius, 6px);
  height: var(--BorderRadius-borderRadius, 6px);
  flex-shrink: 0;
  background: #c9cdd4;
  border-radius: 50%;
}
::v-deep .el-timeline-item__node-- {
  content: '';
  width: var(--BorderRadius-borderRadius, 6px);
  height: var(--BorderRadius-borderRadius, 6px);
  flex-shrink: 0;
  background: transparent;
  width: 14px;
  height: 14px;
  left: 0px;
  top: -2px;
  border-radius: 50%;
}
::v-deep .el-timeline-item__node--large::before {
  content: '';
  width: var(--BorderRadius-borderRadius, 6px);
  height: var(--BorderRadius-borderRadius, 6px);
  flex-shrink: 0;
  background: var(--current-color);
  border-radius: 50%;
}
::v-deep .el-timeline {
  width: 100%;
  padding-top: 2px;
}
.inner-info-box {
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--PC--, #e5e6eb);
  background: #fff;
  min-width: 0;
  .detail-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    flex: 1;
    .info-panel {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: var(--Space-Padding-paddingSM, 12px);
      color: var(--PC-, #4e5969);
      font-family: 'PingFang SC';
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
    .info-number {
      color: var(--PC-, #1d2129);
      font-family: Alternate-font;
      font-size: 28px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
    .info-suffix {
      align-self: stretch;
      color: var(--PC-, #86909c);
      font-family: 'PingFang SC';
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    .minus-box {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
    }
    .equipment-icon {
      color: #000;
      font-family: 'PingFang SC';
      font-size: 32px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }
  .inner-info-row {
    display: flex;
    align-items: center;
    gap: 40px;
    align-self: stretch;
    justify-content: space-between;
    .inner-info-row-item {
      display: flex;
      align-items: flex-start;
      gap: 8px;
      align-self: stretch;
      align-items: center;
    }
    .inner-info-row-layer {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 4px;
      min-width: 120px;
      .inner-info-row-label {
        color: var(--PC-, #4e5969);
        font-family: 'PingFang SC';
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      .inner-info-row-time {
        display: flex;
        align-items: center;
        gap: 4px;
        color: var(--PC-, #1d2129);
        font-family: 'PingFang SC';
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
      .inner-info-row-suffix {
        display: flex;
        align-items: flex-start;
        gap: 8px;
        color: var(--PC-, #86909c);
        font-family: 'PingFang SC';
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }
}
.chart-box-layer {
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  gap: 12px var(--Space-Padding-paddingSM, 12px);
  align-self: stretch;
  flex-wrap: wrap;
  .chart-box {
    flex: 1;
    border-radius: 8px;
    border: 1px solid var(--PC--, #e5e6eb);
    display: flex;
    width: 100%;
    height: 100%;
    min-width: 40%;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--Space-Padding-paddingSM, 12px);
    font-family: 'PingFang SC';
    font-size: 14px;
    font-style: normal;
    color: var(--PC-, #1d2129);
    font-weight: 500;
    line-height: normal;
  }
  .chart-box-title {
    margin: 16px 0 0 16px;
  }
}
.timeline-box-container {
  flex: 1;
  overflow: auto;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  .timeline-box-container-inner {
    position: absolute;
    width: 100%;
    overflow: auto;
    height: 100%;
    padding-top: 10px;

    &::-webkit-scrollbar {
      width: 4px;
      border-radius: 99px;
      background: transparent;
      position: absolute;
      right: 4px;
      bottom: 56px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: var(--fill-4, #c9cdd4);
    }
    ::v-deep .el-timeline-item {
      padding-bottom: 10px;
    }
  }
}
.mini-tag-icon {
  width: var(--Space-Padding-paddingSM, 12px);
  height: var(--Space-Padding-paddingSM, 12px);
}
.card-icon {
  width: 48px;
  height: 48px;
}
::v-deep .el-drawer {
  border-radius: 8px 0px 0px 8px;
}
::v-deep .el-drawer__header {
  padding: 16px 24px;
  margin-bottom: 24px;
  font-weight: 500;
  color: var(--PC-, #1d2129);
  border-bottom: var(--PC--, #e5e6eb) solid 1px;
}
::v-deep .el-drawer__body {
  display: flex;
  align-items: flex-start;
  gap: 16px;
  flex: 1 0 0;
  align-self: stretch;
  flex-direction: column;
}
::v-deep .el-drawer__close-btn {
  position: absolute;
  right: 10px;
  color: #86909c;
}
.drawer-footer {
  display: flex;
  height: 53px;
  border-top: 1px solid var(--PC--, #e5e6eb);
  padding: 10px 0;
  flex-direction: column;
  align-items: flex-end;
  gap: 10px;
  align-self: stretch;
  .drawer-footer-inner {
    display: flex;
    padding: 0px 24px;
    justify-content: flex-end;
    align-items: center;
    gap: 8px;
    align-self: stretch;
  }
}
.text-btn-error {
  color: var(--PC-, #df3737);
  font-family: 'PingFang SC';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
}

.refund-tips-text {
  color: var(--PC-, #4e5969);
  text-align: right;
  font-family: 'PingFang SC';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  align-items: center;
}

::v-deep .el-dialog {
  border-radius: 8px;
}
</style>
